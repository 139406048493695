import { BrowserTracing } from "@sentry/browser"
import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV !== "production") return
  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: "https://77ff90f0324fe62a29336c7a810df44f@o4505942335815680.ingest.sentry.io/4505942772744192",
    environment: process.env.NODE_ENV,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    trackComponents: false,

    integrations: [
      new Sentry.Replay(),

      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        )
      }
      // Continue sending to Sentry
      return event
    }
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ["activate", "create", "destroy", "mount", "update"]
    })
  )
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "create", "destroy", "mount", "update"]
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})
