export default defineNuxtRouteMiddleware((to, from) => {
  if (to.fullPath.startsWith("/profile")) return

  const { data, status } = useAuth()

  if (
    status.value === "authenticated" &&
    isEmptyString(data.value?.user?.name)
  ) {
    return navigateTo("/profile?showPrompt=true")
  }

  return
})

function isEmptyString(name: string | null | undefined) {
  if (!name) return true
  if (name.trim() === "") return true
  return false
}
