import VueGtag, { trackRouter } from "vue-gtag-next"

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV === "production") {
    nuxtApp.vueApp.use(VueGtag, {
      property: {
        id: "G-5BXJC94GQT"
      }
    })
    trackRouter(useRouter())
  }
})
