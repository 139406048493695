<script setup lang="ts">
const props = defineProps({
  error: Object,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div
    class="flex h-screen flex-col justify-center gap-6 bg-white px-8 text-center"
  >
    <h1 class="text-9xl font-black text-gray-200">{{ error.statusCode }}</h1>

    <p
      class="break-words text-xl font-light tracking-tight text-gray-900 sm:text-4xl"
    >
      {{ error.statusMessage || error.message }}
    </p>

    <a
      class="mt-6 inline-block rounded bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring"
      @click.prevent.stop="handleError"
    >
      Go Back Home
    </a>
    <div class="overflow-auto text-xs" v-html="error.stack" />
  </div>
</template>
