import { default as editIbC0mfSgxqMeta } from "/app/apps/customer-portal/pages/admin/companies/[id]/edit.vue?macro=true";
import { default as indexgbcwSxh6c7Meta } from "/app/apps/customer-portal/pages/admin/companies/index.vue?macro=true";
import { default as newTotyOoa6hSMeta } from "/app/apps/customer-portal/pages/admin/companies/new.vue?macro=true";
import { default as indexTTjaXkjq71Meta } from "/app/apps/customer-portal/pages/admin/counties/index.vue?macro=true";
import { default as newqI0MqA4CYhMeta } from "/app/apps/customer-portal/pages/admin/counties/new.vue?macro=true";
import { default as editzcYkxw5rgJMeta } from "/app/apps/customer-portal/pages/admin/diy_descriptions/[id]/edit.vue?macro=true";
import { default as indexZKmauzOaV0Meta } from "/app/apps/customer-portal/pages/admin/diy_descriptions/index.vue?macro=true";
import { default as newATzmXRU9lcMeta } from "/app/apps/customer-portal/pages/admin/diy_descriptions/new.vue?macro=true";
import { default as index5Bv7NBuverMeta } from "/app/apps/customer-portal/pages/admin/gigs/index.vue?macro=true";
import { default as newQvmnllIFL7Meta } from "/app/apps/customer-portal/pages/admin/gigs/new.vue?macro=true";
import { default as indexquE3YeGe7RMeta } from "/app/apps/customer-portal/pages/admin/index.vue?macro=true";
import { default as editUeEzR58QvTMeta } from "/app/apps/customer-portal/pages/admin/maintenance/[id]/edit.vue?macro=true";
import { default as indexeVVaFkgFO7Meta } from "/app/apps/customer-portal/pages/admin/maintenance/index.vue?macro=true";
import { default as newTyYMmAuzdzMeta } from "/app/apps/customer-portal/pages/admin/maintenance/new.vue?macro=true";
import { default as paymentsiAAxIyaWhMMeta } from "/app/apps/customer-portal/pages/admin/payments.vue?macro=true";
import { default as indexy0bJwGNNs0Meta } from "/app/apps/customer-portal/pages/admin/workorders/[workorder_id]/index.vue?macro=true";
import { default as indexBHROEz2GBXMeta } from "/app/apps/customer-portal/pages/admin/workorders/index.vue?macro=true";
import { default as logineunpHX8WLcMeta } from "/app/apps/customer-portal/pages/auth/login.vue?macro=true";
import { default as signoutBaQljsC9t0Meta } from "/app/apps/customer-portal/pages/auth/signout.vue?macro=true";
import { default as verify_45requestiPrUSpwsYAMeta } from "/app/apps/customer-portal/pages/auth/verify-request.vue?macro=true";
import { default as earnm9SrFJP2rUMeta } from "/app/apps/customer-portal/pages/earn.vue?macro=true";
import { default as feedbackgMCyntyYBiMeta } from "/app/apps/customer-portal/pages/feedback.vue?macro=true";
import { default as index1VgRY4LH3UMeta } from "/app/apps/customer-portal/pages/gigs/index.vue?macro=true";
import { default as indexstSTGhtAPdMeta } from "/app/apps/customer-portal/pages/gigs/payments/index.vue?macro=true";
import { default as editTrbR7lEN7hMeta } from "/app/apps/customer-portal/pages/gigs/profiles/[gig_profile]/edit.vue?macro=true";
import { default as showwDqM1K3P5YMeta } from "/app/apps/customer-portal/pages/gigs/profiles/[gig_profile]/show.vue?macro=true";
import { default as indext29nVNM3gCMeta } from "/app/apps/customer-portal/pages/gigs/profiles/index.vue?macro=true";
import { default as newH2akEu43GNMeta } from "/app/apps/customer-portal/pages/gigs/profiles/new.vue?macro=true";
import { default as edit_datescNhNeK4E4KMeta } from "/app/apps/customer-portal/pages/gigs/schedule/edit_dates.vue?macro=true";
import { default as edit_wday3yV4SQlKp3Meta } from "/app/apps/customer-portal/pages/gigs/schedule/edit_wday.vue?macro=true";
import { default as indexNwqUr73WXIMeta } from "/app/apps/customer-portal/pages/gigs/schedule/index.vue?macro=true";
import { default as fire_45safetyR4kJDmRCwFMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/fire-safety.vue?macro=true";
import { default as bookPbOlmLLqWUMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/[profile_id]/book.vue?macro=true";
import { default as indexuOCzO2JZCiMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/[profile_id]/index.vue?macro=true";
import { default as indexwmEdLZItNmMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/index.vue?macro=true";
import { default as indexVXWq2XsznQMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/index.vue?macro=true";
import { default as indexDcZ9TZMlxrMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/index.vue?macro=true";
import { default as _91tag_93vlSH6VOTgOMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/[tag].vue?macro=true";
import { default as indexPwweDDmyI5Meta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/index.vue?macro=true";
import { default as _91company_id_93VXXgf5r46qMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/show/[company_id].vue?macro=true";
import { default as _91description_id_93WIYJv8Z9JTMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/reminders/[maintenance_id]/diy/[description_id].vue?macro=true";
import { default as indexOkjmsvUAIHMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/reminders/[maintenance_id]/index.vue?macro=true";
import { default as index6h4xV3Cz1jMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/scheduler/index.vue?macro=true";
import { default as seasonalVGD1ZVYMsrMeta } from "/app/apps/customer-portal/pages/homes/[user_home_id]/seasonal.vue?macro=true";
import { default as indexe2colRyiVxMeta } from "/app/apps/customer-portal/pages/index.vue?macro=true";
import { default as delete2kurruEWPtMeta } from "/app/apps/customer-portal/pages/profile/delete.vue?macro=true";
import { default as indexd4TbWVcd78Meta } from "/app/apps/customer-portal/pages/profile/index.vue?macro=true";
import { default as test93CarCwGOBMeta } from "/app/apps/customer-portal/pages/test.vue?macro=true";
import { default as editu9NCCdPM3KMeta } from "/app/apps/customer-portal/pages/workorders/[workorder_id]/edit.vue?macro=true";
import { default as index3JYHUZZXHpMeta } from "/app/apps/customer-portal/pages/workorders/[workorder_id]/index.vue?macro=true";
import { default as rescheduleuFuryzeYh0Meta } from "/app/apps/customer-portal/pages/workorders/[workorder_id]/reschedule.vue?macro=true";
import { default as indexuWukDuh7bxMeta } from "/app/apps/customer-portal/pages/workorders/index.vue?macro=true";
import { default as newDQ5lbnlQDyMeta } from "/app/apps/customer-portal/pages/workorders/new.vue?macro=true";
export default [
  {
    name: editIbC0mfSgxqMeta?.name ?? "admin-companies-id-edit",
    path: editIbC0mfSgxqMeta?.path ?? "/admin/companies/:id()/edit",
    meta: editIbC0mfSgxqMeta || {},
    alias: editIbC0mfSgxqMeta?.alias || [],
    redirect: editIbC0mfSgxqMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/companies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexgbcwSxh6c7Meta?.name ?? "admin-companies",
    path: indexgbcwSxh6c7Meta?.path ?? "/admin/companies",
    meta: indexgbcwSxh6c7Meta || {},
    alias: indexgbcwSxh6c7Meta?.alias || [],
    redirect: indexgbcwSxh6c7Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/companies/index.vue").then(m => m.default || m)
  },
  {
    name: newTotyOoa6hSMeta?.name ?? "admin-companies-new",
    path: newTotyOoa6hSMeta?.path ?? "/admin/companies/new",
    meta: newTotyOoa6hSMeta || {},
    alias: newTotyOoa6hSMeta?.alias || [],
    redirect: newTotyOoa6hSMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/companies/new.vue").then(m => m.default || m)
  },
  {
    name: indexTTjaXkjq71Meta?.name ?? "admin-counties",
    path: indexTTjaXkjq71Meta?.path ?? "/admin/counties",
    meta: indexTTjaXkjq71Meta || {},
    alias: indexTTjaXkjq71Meta?.alias || [],
    redirect: indexTTjaXkjq71Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/counties/index.vue").then(m => m.default || m)
  },
  {
    name: newqI0MqA4CYhMeta?.name ?? "admin-counties-new",
    path: newqI0MqA4CYhMeta?.path ?? "/admin/counties/new",
    meta: newqI0MqA4CYhMeta || {},
    alias: newqI0MqA4CYhMeta?.alias || [],
    redirect: newqI0MqA4CYhMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/counties/new.vue").then(m => m.default || m)
  },
  {
    name: editzcYkxw5rgJMeta?.name ?? "admin-diy_descriptions-id-edit",
    path: editzcYkxw5rgJMeta?.path ?? "/admin/diy_descriptions/:id()/edit",
    meta: editzcYkxw5rgJMeta || {},
    alias: editzcYkxw5rgJMeta?.alias || [],
    redirect: editzcYkxw5rgJMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/diy_descriptions/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZKmauzOaV0Meta?.name ?? "admin-diy_descriptions",
    path: indexZKmauzOaV0Meta?.path ?? "/admin/diy_descriptions",
    meta: indexZKmauzOaV0Meta || {},
    alias: indexZKmauzOaV0Meta?.alias || [],
    redirect: indexZKmauzOaV0Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/diy_descriptions/index.vue").then(m => m.default || m)
  },
  {
    name: newATzmXRU9lcMeta?.name ?? "admin-diy_descriptions-new",
    path: newATzmXRU9lcMeta?.path ?? "/admin/diy_descriptions/new",
    meta: newATzmXRU9lcMeta || {},
    alias: newATzmXRU9lcMeta?.alias || [],
    redirect: newATzmXRU9lcMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/diy_descriptions/new.vue").then(m => m.default || m)
  },
  {
    name: index5Bv7NBuverMeta?.name ?? "admin-gigs",
    path: index5Bv7NBuverMeta?.path ?? "/admin/gigs",
    meta: index5Bv7NBuverMeta || {},
    alias: index5Bv7NBuverMeta?.alias || [],
    redirect: index5Bv7NBuverMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/gigs/index.vue").then(m => m.default || m)
  },
  {
    name: newQvmnllIFL7Meta?.name ?? "admin-gigs-new",
    path: newQvmnllIFL7Meta?.path ?? "/admin/gigs/new",
    meta: newQvmnllIFL7Meta || {},
    alias: newQvmnllIFL7Meta?.alias || [],
    redirect: newQvmnllIFL7Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/gigs/new.vue").then(m => m.default || m)
  },
  {
    name: indexquE3YeGe7RMeta?.name ?? "admin",
    path: indexquE3YeGe7RMeta?.path ?? "/admin",
    meta: indexquE3YeGe7RMeta || {},
    alias: indexquE3YeGe7RMeta?.alias || [],
    redirect: indexquE3YeGe7RMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: editUeEzR58QvTMeta?.name ?? "admin-maintenance-id-edit",
    path: editUeEzR58QvTMeta?.path ?? "/admin/maintenance/:id()/edit",
    meta: editUeEzR58QvTMeta || {},
    alias: editUeEzR58QvTMeta?.alias || [],
    redirect: editUeEzR58QvTMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/maintenance/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeVVaFkgFO7Meta?.name ?? "admin-maintenance",
    path: indexeVVaFkgFO7Meta?.path ?? "/admin/maintenance",
    meta: indexeVVaFkgFO7Meta || {},
    alias: indexeVVaFkgFO7Meta?.alias || [],
    redirect: indexeVVaFkgFO7Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: newTyYMmAuzdzMeta?.name ?? "admin-maintenance-new",
    path: newTyYMmAuzdzMeta?.path ?? "/admin/maintenance/new",
    meta: newTyYMmAuzdzMeta || {},
    alias: newTyYMmAuzdzMeta?.alias || [],
    redirect: newTyYMmAuzdzMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/maintenance/new.vue").then(m => m.default || m)
  },
  {
    name: paymentsiAAxIyaWhMMeta?.name ?? "admin-payments",
    path: paymentsiAAxIyaWhMMeta?.path ?? "/admin/payments",
    meta: paymentsiAAxIyaWhMMeta || {},
    alias: paymentsiAAxIyaWhMMeta?.alias || [],
    redirect: paymentsiAAxIyaWhMMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/payments.vue").then(m => m.default || m)
  },
  {
    name: indexy0bJwGNNs0Meta?.name ?? "admin-workorders-workorder_id",
    path: indexy0bJwGNNs0Meta?.path ?? "/admin/workorders/:workorder_id()",
    meta: indexy0bJwGNNs0Meta || {},
    alias: indexy0bJwGNNs0Meta?.alias || [],
    redirect: indexy0bJwGNNs0Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/workorders/[workorder_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBHROEz2GBXMeta?.name ?? "admin-workorders",
    path: indexBHROEz2GBXMeta?.path ?? "/admin/workorders",
    meta: indexBHROEz2GBXMeta || {},
    alias: indexBHROEz2GBXMeta?.alias || [],
    redirect: indexBHROEz2GBXMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/admin/workorders/index.vue").then(m => m.default || m)
  },
  {
    name: logineunpHX8WLcMeta?.name ?? "auth-login",
    path: logineunpHX8WLcMeta?.path ?? "/auth/login",
    meta: logineunpHX8WLcMeta || {},
    alias: logineunpHX8WLcMeta?.alias || [],
    redirect: logineunpHX8WLcMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: signoutBaQljsC9t0Meta?.name ?? "auth-signout",
    path: signoutBaQljsC9t0Meta?.path ?? "/auth/signout",
    meta: signoutBaQljsC9t0Meta || {},
    alias: signoutBaQljsC9t0Meta?.alias || [],
    redirect: signoutBaQljsC9t0Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/auth/signout.vue").then(m => m.default || m)
  },
  {
    name: verify_45requestiPrUSpwsYAMeta?.name ?? "auth-verify-request",
    path: verify_45requestiPrUSpwsYAMeta?.path ?? "/auth/verify-request",
    meta: verify_45requestiPrUSpwsYAMeta || {},
    alias: verify_45requestiPrUSpwsYAMeta?.alias || [],
    redirect: verify_45requestiPrUSpwsYAMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/auth/verify-request.vue").then(m => m.default || m)
  },
  {
    name: earnm9SrFJP2rUMeta?.name ?? "earn",
    path: earnm9SrFJP2rUMeta?.path ?? "/earn",
    meta: earnm9SrFJP2rUMeta || {},
    alias: earnm9SrFJP2rUMeta?.alias || [],
    redirect: earnm9SrFJP2rUMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/earn.vue").then(m => m.default || m)
  },
  {
    name: feedbackgMCyntyYBiMeta?.name ?? "feedback",
    path: feedbackgMCyntyYBiMeta?.path ?? "/feedback",
    meta: feedbackgMCyntyYBiMeta || {},
    alias: feedbackgMCyntyYBiMeta?.alias || [],
    redirect: feedbackgMCyntyYBiMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: index1VgRY4LH3UMeta?.name ?? "gigs",
    path: index1VgRY4LH3UMeta?.path ?? "/gigs",
    meta: index1VgRY4LH3UMeta || {},
    alias: index1VgRY4LH3UMeta?.alias || [],
    redirect: index1VgRY4LH3UMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/index.vue").then(m => m.default || m)
  },
  {
    name: indexstSTGhtAPdMeta?.name ?? "gigs-payments",
    path: indexstSTGhtAPdMeta?.path ?? "/gigs/payments",
    meta: indexstSTGhtAPdMeta || {},
    alias: indexstSTGhtAPdMeta?.alias || [],
    redirect: indexstSTGhtAPdMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/payments/index.vue").then(m => m.default || m)
  },
  {
    name: editTrbR7lEN7hMeta?.name ?? "gigs-profiles-gig_profile-edit",
    path: editTrbR7lEN7hMeta?.path ?? "/gigs/profiles/:gig_profile()/edit",
    meta: editTrbR7lEN7hMeta || {},
    alias: editTrbR7lEN7hMeta?.alias || [],
    redirect: editTrbR7lEN7hMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/profiles/[gig_profile]/edit.vue").then(m => m.default || m)
  },
  {
    name: showwDqM1K3P5YMeta?.name ?? "gigs-profiles-gig_profile-show",
    path: showwDqM1K3P5YMeta?.path ?? "/gigs/profiles/:gig_profile()/show",
    meta: showwDqM1K3P5YMeta || {},
    alias: showwDqM1K3P5YMeta?.alias || [],
    redirect: showwDqM1K3P5YMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/profiles/[gig_profile]/show.vue").then(m => m.default || m)
  },
  {
    name: indext29nVNM3gCMeta?.name ?? "gigs-profiles",
    path: indext29nVNM3gCMeta?.path ?? "/gigs/profiles",
    meta: indext29nVNM3gCMeta || {},
    alias: indext29nVNM3gCMeta?.alias || [],
    redirect: indext29nVNM3gCMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/profiles/index.vue").then(m => m.default || m)
  },
  {
    name: newH2akEu43GNMeta?.name ?? "gigs-profiles-new",
    path: newH2akEu43GNMeta?.path ?? "/gigs/profiles/new",
    meta: newH2akEu43GNMeta || {},
    alias: newH2akEu43GNMeta?.alias || [],
    redirect: newH2akEu43GNMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/profiles/new.vue").then(m => m.default || m)
  },
  {
    name: edit_datescNhNeK4E4KMeta?.name ?? "gigs-schedule-edit_dates",
    path: edit_datescNhNeK4E4KMeta?.path ?? "/gigs/schedule/edit_dates",
    meta: edit_datescNhNeK4E4KMeta || {},
    alias: edit_datescNhNeK4E4KMeta?.alias || [],
    redirect: edit_datescNhNeK4E4KMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/schedule/edit_dates.vue").then(m => m.default || m)
  },
  {
    name: edit_wday3yV4SQlKp3Meta?.name ?? "gigs-schedule-edit_wday",
    path: edit_wday3yV4SQlKp3Meta?.path ?? "/gigs/schedule/edit_wday",
    meta: edit_wday3yV4SQlKp3Meta || {},
    alias: edit_wday3yV4SQlKp3Meta?.alias || [],
    redirect: edit_wday3yV4SQlKp3Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/schedule/edit_wday.vue").then(m => m.default || m)
  },
  {
    name: indexNwqUr73WXIMeta?.name ?? "gigs-schedule",
    path: indexNwqUr73WXIMeta?.path ?? "/gigs/schedule",
    meta: indexNwqUr73WXIMeta || {},
    alias: indexNwqUr73WXIMeta?.alias || [],
    redirect: indexNwqUr73WXIMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/gigs/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: fire_45safetyR4kJDmRCwFMeta?.name ?? "homes-user_home_id-fire-safety",
    path: fire_45safetyR4kJDmRCwFMeta?.path ?? "/homes/:user_home_id()/fire-safety",
    meta: fire_45safetyR4kJDmRCwFMeta || {},
    alias: fire_45safetyR4kJDmRCwFMeta?.alias || [],
    redirect: fire_45safetyR4kJDmRCwFMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/fire-safety.vue").then(m => m.default || m)
  },
  {
    name: bookPbOlmLLqWUMeta?.name ?? "homes-user_home_id-gig-workers-gig_id-profile_id-book",
    path: bookPbOlmLLqWUMeta?.path ?? "/homes/:user_home_id()/gig-workers/:gig_id()/:profile_id()/book",
    meta: bookPbOlmLLqWUMeta || {},
    alias: bookPbOlmLLqWUMeta?.alias || [],
    redirect: bookPbOlmLLqWUMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/[profile_id]/book.vue").then(m => m.default || m)
  },
  {
    name: indexuOCzO2JZCiMeta?.name ?? "homes-user_home_id-gig-workers-gig_id-profile_id",
    path: indexuOCzO2JZCiMeta?.path ?? "/homes/:user_home_id()/gig-workers/:gig_id()/:profile_id()",
    meta: indexuOCzO2JZCiMeta || {},
    alias: indexuOCzO2JZCiMeta?.alias || [],
    redirect: indexuOCzO2JZCiMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/[profile_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwmEdLZItNmMeta?.name ?? "homes-user_home_id-gig-workers-gig_id",
    path: indexwmEdLZItNmMeta?.path ?? "/homes/:user_home_id()/gig-workers/:gig_id()",
    meta: indexwmEdLZItNmMeta || {},
    alias: indexwmEdLZItNmMeta?.alias || [],
    redirect: indexwmEdLZItNmMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/[gig_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVXWq2XsznQMeta?.name ?? "homes-user_home_id-gig-workers",
    path: indexVXWq2XsznQMeta?.path ?? "/homes/:user_home_id()/gig-workers",
    meta: indexVXWq2XsznQMeta || {},
    alias: indexVXWq2XsznQMeta?.alias || [],
    redirect: indexVXWq2XsznQMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/gig-workers/index.vue").then(m => m.default || m)
  },
  {
    name: indexDcZ9TZMlxrMeta?.name ?? "homes-user_home_id",
    path: indexDcZ9TZMlxrMeta?.path ?? "/homes/:user_home_id()",
    meta: indexDcZ9TZMlxrMeta || {},
    alias: indexDcZ9TZMlxrMeta?.alias || [],
    redirect: indexDcZ9TZMlxrMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91tag_93vlSH6VOTgOMeta?.name ?? "homes-user_home_id-pro-servicers-tag",
    path: _91tag_93vlSH6VOTgOMeta?.path ?? "/homes/:user_home_id()/pro-servicers/:tag()",
    meta: _91tag_93vlSH6VOTgOMeta || {},
    alias: _91tag_93vlSH6VOTgOMeta?.alias || [],
    redirect: _91tag_93vlSH6VOTgOMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/[tag].vue").then(m => m.default || m)
  },
  {
    name: indexPwweDDmyI5Meta?.name ?? "homes-user_home_id-pro-servicers",
    path: indexPwweDDmyI5Meta?.path ?? "/homes/:user_home_id()/pro-servicers",
    meta: indexPwweDDmyI5Meta || {},
    alias: indexPwweDDmyI5Meta?.alias || [],
    redirect: indexPwweDDmyI5Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/index.vue").then(m => m.default || m)
  },
  {
    name: _91company_id_93VXXgf5r46qMeta?.name ?? "homes-user_home_id-pro-servicers-show-company_id",
    path: _91company_id_93VXXgf5r46qMeta?.path ?? "/homes/:user_home_id()/pro-servicers/show/:company_id()",
    meta: _91company_id_93VXXgf5r46qMeta || {},
    alias: _91company_id_93VXXgf5r46qMeta?.alias || [],
    redirect: _91company_id_93VXXgf5r46qMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/pro-servicers/show/[company_id].vue").then(m => m.default || m)
  },
  {
    name: _91description_id_93WIYJv8Z9JTMeta?.name ?? "homes-user_home_id-reminders-maintenance_id-diy-description_id",
    path: _91description_id_93WIYJv8Z9JTMeta?.path ?? "/homes/:user_home_id()/reminders/:maintenance_id()/diy/:description_id()",
    meta: _91description_id_93WIYJv8Z9JTMeta || {},
    alias: _91description_id_93WIYJv8Z9JTMeta?.alias || [],
    redirect: _91description_id_93WIYJv8Z9JTMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/reminders/[maintenance_id]/diy/[description_id].vue").then(m => m.default || m)
  },
  {
    name: indexOkjmsvUAIHMeta?.name ?? "homes-user_home_id-reminders-maintenance_id",
    path: indexOkjmsvUAIHMeta?.path ?? "/homes/:user_home_id()/reminders/:maintenance_id()",
    meta: indexOkjmsvUAIHMeta || {},
    alias: indexOkjmsvUAIHMeta?.alias || [],
    redirect: indexOkjmsvUAIHMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/reminders/[maintenance_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index6h4xV3Cz1jMeta?.name ?? "homes-user_home_id-scheduler",
    path: index6h4xV3Cz1jMeta?.path ?? "/homes/:user_home_id()/scheduler",
    meta: index6h4xV3Cz1jMeta || {},
    alias: index6h4xV3Cz1jMeta?.alias || [],
    redirect: index6h4xV3Cz1jMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/scheduler/index.vue").then(m => m.default || m)
  },
  {
    name: seasonalVGD1ZVYMsrMeta?.name ?? "homes-user_home_id-seasonal",
    path: seasonalVGD1ZVYMsrMeta?.path ?? "/homes/:user_home_id()/seasonal",
    meta: seasonalVGD1ZVYMsrMeta || {},
    alias: seasonalVGD1ZVYMsrMeta?.alias || [],
    redirect: seasonalVGD1ZVYMsrMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/homes/[user_home_id]/seasonal.vue").then(m => m.default || m)
  },
  {
    name: indexe2colRyiVxMeta?.name ?? "index",
    path: indexe2colRyiVxMeta?.path ?? "/",
    meta: indexe2colRyiVxMeta || {},
    alias: indexe2colRyiVxMeta?.alias || [],
    redirect: indexe2colRyiVxMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: delete2kurruEWPtMeta?.name ?? "profile-delete",
    path: delete2kurruEWPtMeta?.path ?? "/profile/delete",
    meta: delete2kurruEWPtMeta || {},
    alias: delete2kurruEWPtMeta?.alias || [],
    redirect: delete2kurruEWPtMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/profile/delete.vue").then(m => m.default || m)
  },
  {
    name: indexd4TbWVcd78Meta?.name ?? "profile",
    path: indexd4TbWVcd78Meta?.path ?? "/profile",
    meta: indexd4TbWVcd78Meta || {},
    alias: indexd4TbWVcd78Meta?.alias || [],
    redirect: indexd4TbWVcd78Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: test93CarCwGOBMeta?.name ?? "test",
    path: test93CarCwGOBMeta?.path ?? "/test",
    meta: test93CarCwGOBMeta || {},
    alias: test93CarCwGOBMeta?.alias || [],
    redirect: test93CarCwGOBMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/test.vue").then(m => m.default || m)
  },
  {
    name: editu9NCCdPM3KMeta?.name ?? "workorders-workorder_id-edit",
    path: editu9NCCdPM3KMeta?.path ?? "/workorders/:workorder_id()/edit",
    meta: editu9NCCdPM3KMeta || {},
    alias: editu9NCCdPM3KMeta?.alias || [],
    redirect: editu9NCCdPM3KMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/workorders/[workorder_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index3JYHUZZXHpMeta?.name ?? "workorders-workorder_id",
    path: index3JYHUZZXHpMeta?.path ?? "/workorders/:workorder_id()",
    meta: index3JYHUZZXHpMeta || {},
    alias: index3JYHUZZXHpMeta?.alias || [],
    redirect: index3JYHUZZXHpMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/workorders/[workorder_id]/index.vue").then(m => m.default || m)
  },
  {
    name: rescheduleuFuryzeYh0Meta?.name ?? "workorders-workorder_id-reschedule",
    path: rescheduleuFuryzeYh0Meta?.path ?? "/workorders/:workorder_id()/reschedule",
    meta: rescheduleuFuryzeYh0Meta || {},
    alias: rescheduleuFuryzeYh0Meta?.alias || [],
    redirect: rescheduleuFuryzeYh0Meta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/workorders/[workorder_id]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: indexuWukDuh7bxMeta?.name ?? "workorders",
    path: indexuWukDuh7bxMeta?.path ?? "/workorders",
    meta: indexuWukDuh7bxMeta || {},
    alias: indexuWukDuh7bxMeta?.alias || [],
    redirect: indexuWukDuh7bxMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/workorders/index.vue").then(m => m.default || m)
  },
  {
    name: newDQ5lbnlQDyMeta?.name ?? "workorders-new",
    path: newDQ5lbnlQDyMeta?.path ?? "/workorders/new",
    meta: newDQ5lbnlQDyMeta || {},
    alias: newDQ5lbnlQDyMeta?.alias || [],
    redirect: newDQ5lbnlQDyMeta?.redirect || undefined,
    component: () => import("/app/apps/customer-portal/pages/workorders/new.vue").then(m => m.default || m)
  }
]