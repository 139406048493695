import validate from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/app/apps/customer-portal/middleware/admin.global.ts";
import empty_45profile_45global from "/app/apps/customer-portal/middleware/empty_profile.global.ts";
import layout_45global from "/app/apps/customer-portal/middleware/layout.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  empty_45profile_45global,
  layout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.2_next-auth@4.21.1_rollup@2.79.1_vue@3.4.19/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}