<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator :height="2" color="#7dd3fc" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { registerSW } from "virtual:pwa-register";

const router = useRouter();

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    if (window.navigator && !window.navigator.onLine) return;

    console.log("Reloading app");

    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});

onMounted(() => {
  // https://vite-pwa-org.netlify.app/guide/auto-update.html
  registerSW({ immediate: true });
});
</script>
