// Middleware to set the layout based on route
export default defineNuxtRouteMiddleware((to, from) => {
  if (to.fullPath.startsWith("/admin")) {
    const { data } = useAuth()
    if (data.value?.user!.role !== "admin") {
      return navigateTo("/")
    }
  }

  return
})
