import revive_payload_client_GDRLFq5tWC from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_T4wWLSTg3G from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JNSUwKfeLm from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_h9swGWKhrb from "/app/node_modules/.pnpm/nuxt-site-config@2.2.8_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.19_nuxt@3.10.3_postcss@8.4_3ol7s3ufkwpsf6vkbwcwzbfjzu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_9Mxx8FQkpZ from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ePvFAOpMx6 from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_lHrNvjVD7V from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_typescript@5.3.3_vue@3.4.19/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/customer-portal/.nuxt/components.plugin.mjs";
import prefetch_client_5UM0GlUwmZ from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_8UyodYtO9p from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.2.3_@nuxt+kit@3.10.3_vite-plugin-pwa@0.19.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_L4rzACPsO7 from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.2_next-auth@4.21.1_rollup@2.79.1_vue@3.4.19/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_5v9F2kLUV8 from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@18.19.2_eslint@8.55.0_rollup@2.79.1_typescript@5.3.3_vite@5.1.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/app/apps/customer-portal/plugins/sentry.client.ts";
import vue_gtag_client_CYt6toK02h from "/app/apps/customer-portal/plugins/vue-gtag.client.ts";
export default [
  revive_payload_client_GDRLFq5tWC,
  unhead_T4wWLSTg3G,
  router_JNSUwKfeLm,
  _0_siteConfig_h9swGWKhrb,
  payload_client_9Mxx8FQkpZ,
  check_outdated_build_client_ePvFAOpMx6,
  plugin_vue3_lHrNvjVD7V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5UM0GlUwmZ,
  pwa_client_8UyodYtO9p,
  plugin_L4rzACPsO7,
  chunk_reload_client_5v9F2kLUV8,
  sentry_client_shVUlIjFLk,
  vue_gtag_client_CYt6toK02h
]