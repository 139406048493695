import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddHomeForm, LazyAuthAlertBox, LazyAvailabilityCalendar, LazyAvailabilityForm, LazyBackButton, LazyButtonBuyNow, LazyCardPanel, LazyCompanyContactUnaffiliated, LazyCompanyForm, LazyCompanyLogo, LazyCompanyTagSelect, LazyCountySelect, LazyDateLabel, LazyDiyDescriptionForm, LazyDiyDescriptionSelect, LazyEmptyGigResultsFiller, LazyEmptyGigsFiller, LazyEmptyHomeFiller, LazyFieldCheckbox, LazyFieldSwitch, LazyFormStep, LazyFormWizard, LazyFreeHomeInspection, LazyGigIdSelect, LazyGigProfileForm, LazyGigSelect, LazyGigWorkerButton, LazyGigWorkerStatusInfo, LazyHomeList, LazyInButtonSpinner, LazyInstructionsButton, LazyInvoiceListBox, LazyMaintenancePanel, LazyMaintenanceSplitPanel, LazyMaintenanceItemForm, LazyModalContainer, LazyPhoneHeader, LazyProButton, LazyReminderActions, LazyReminderStatus, LazyReminderStatusUpdater, LazyScheduleOverrides, LazyScheduleWeekly, LazyScheduler, LazySearchInput, LazySkeletonDiy, LazySkeletonHome, LazySkeletonImage, LazySkeletonMaintenanceList, LazySkeletonTagList, LazySkeletonText, LazySkeletonUserList, LazySkeletonWorkOrderList, LazySkeletonWorkOrderView, LazyStarRating, LazyStripeOnboardAccountActive, LazyStripeOnboardAccountButton, LazyStripeOnboardAccountVerification, LazyStripePaymentManagementForm, LazyStripePaymentMethodForm, LazySwitchField, LazyTabBar, LazyTaskPrice, LazyTechStatsMemory, LazyTechStatsNetwork, LazyTermsOfUse, LazyTransitionFade, LazyUpcomingMaintenance, LazyUserAvatar, LazyUserName, LazyWorkorderActivity, LazyWorkorderDetails, LazyWorkorderGigList, LazyWorkorderList, LazyWorkorderMessage, LazyWorkorderStatus, LazyWorkorderStatusSelect, LazyWorkorderTabs, LazyWorkorderThankYouPartial, LazySvgoGigWorker, LazySvgoHandshake, LazySvgoHardHat, LazySvgoOliveBranch } from '#components'
const lazyGlobalComponents = [
  ["AddHomeForm", LazyAddHomeForm],
["AuthAlertBox", LazyAuthAlertBox],
["AvailabilityCalendar", LazyAvailabilityCalendar],
["AvailabilityForm", LazyAvailabilityForm],
["BackButton", LazyBackButton],
["ButtonBuyNow", LazyButtonBuyNow],
["CardPanel", LazyCardPanel],
["CompanyContactUnaffiliated", LazyCompanyContactUnaffiliated],
["CompanyForm", LazyCompanyForm],
["CompanyLogo", LazyCompanyLogo],
["CompanyTagSelect", LazyCompanyTagSelect],
["CountySelect", LazyCountySelect],
["DateLabel", LazyDateLabel],
["DiyDescriptionForm", LazyDiyDescriptionForm],
["DiyDescriptionSelect", LazyDiyDescriptionSelect],
["EmptyGigResultsFiller", LazyEmptyGigResultsFiller],
["EmptyGigsFiller", LazyEmptyGigsFiller],
["EmptyHomeFiller", LazyEmptyHomeFiller],
["FieldCheckbox", LazyFieldCheckbox],
["FieldSwitch", LazyFieldSwitch],
["FormStep", LazyFormStep],
["FormWizard", LazyFormWizard],
["FreeHomeInspection", LazyFreeHomeInspection],
["GigIdSelect", LazyGigIdSelect],
["GigProfileForm", LazyGigProfileForm],
["GigSelect", LazyGigSelect],
["GigWorkerButton", LazyGigWorkerButton],
["GigWorkerStatusInfo", LazyGigWorkerStatusInfo],
["HomeList", LazyHomeList],
["InButtonSpinner", LazyInButtonSpinner],
["InstructionsButton", LazyInstructionsButton],
["InvoiceListBox", LazyInvoiceListBox],
["MaintenancePanel", LazyMaintenancePanel],
["MaintenanceSplitPanel", LazyMaintenanceSplitPanel],
["MaintenanceItemForm", LazyMaintenanceItemForm],
["ModalContainer", LazyModalContainer],
["PhoneHeader", LazyPhoneHeader],
["ProButton", LazyProButton],
["ReminderActions", LazyReminderActions],
["ReminderStatus", LazyReminderStatus],
["ReminderStatusUpdater", LazyReminderStatusUpdater],
["ScheduleOverrides", LazyScheduleOverrides],
["ScheduleWeekly", LazyScheduleWeekly],
["Scheduler", LazyScheduler],
["SearchInput", LazySearchInput],
["SkeletonDiy", LazySkeletonDiy],
["SkeletonHome", LazySkeletonHome],
["SkeletonImage", LazySkeletonImage],
["SkeletonMaintenanceList", LazySkeletonMaintenanceList],
["SkeletonTagList", LazySkeletonTagList],
["SkeletonText", LazySkeletonText],
["SkeletonUserList", LazySkeletonUserList],
["SkeletonWorkOrderList", LazySkeletonWorkOrderList],
["SkeletonWorkOrderView", LazySkeletonWorkOrderView],
["StarRating", LazyStarRating],
["StripeOnboardAccountActive", LazyStripeOnboardAccountActive],
["StripeOnboardAccountButton", LazyStripeOnboardAccountButton],
["StripeOnboardAccountVerification", LazyStripeOnboardAccountVerification],
["StripePaymentManagementForm", LazyStripePaymentManagementForm],
["StripePaymentMethodForm", LazyStripePaymentMethodForm],
["SwitchField", LazySwitchField],
["TabBar", LazyTabBar],
["TaskPrice", LazyTaskPrice],
["TechStatsMemory", LazyTechStatsMemory],
["TechStatsNetwork", LazyTechStatsNetwork],
["TermsOfUse", LazyTermsOfUse],
["TransitionFade", LazyTransitionFade],
["UpcomingMaintenance", LazyUpcomingMaintenance],
["UserAvatar", LazyUserAvatar],
["UserName", LazyUserName],
["WorkorderActivity", LazyWorkorderActivity],
["WorkorderDetails", LazyWorkorderDetails],
["WorkorderGigList", LazyWorkorderGigList],
["WorkorderList", LazyWorkorderList],
["WorkorderMessage", LazyWorkorderMessage],
["WorkorderStatus", LazyWorkorderStatus],
["WorkorderStatusSelect", LazyWorkorderStatusSelect],
["WorkorderTabs", LazyWorkorderTabs],
["WorkorderThankYouPartial", LazyWorkorderThankYouPartial],
["SvgoGigWorker", LazySvgoGigWorker],
["SvgoHandshake", LazySvgoHandshake],
["SvgoHardHat", LazySvgoHardHat],
["SvgoOliveBranch", LazySvgoOliveBranch],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
